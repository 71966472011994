.qz-choice-button {
  border: none; /*1px solid black;*/
  font-size: 3rem;
  padding: 10px 20px;
  background-color: transparent;/*white*/
  font-weight: bold;
  /*height: 100%;*/
  color: white;
}
.qz-choice-button.qz-selected {
  background-color: #c9c9c9 !important;
}
.qz-choice-button.qz-correct {
  background-color: #efdf00 !important;
}
.qz-choice-button.qz-wrong {
  background-color: #c9c9c9 !important;
}
.qz-question-guide {
  font-size: 5rem;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 140px;
  /*direction: rtl;*/
}
.qz-question-guide .qz-icon {
  display: inline-block;
  width: 47px;
  height: 126px;
  margin-left: 90px;
  vertical-align: middle;
  background: url('../../assets/img/right-arrow.png') no-repeat center center;
}
.qz-question-text {
  padding: 0 20px 40px;
  font-size: 3rem;
  line-height: 70px;
}
.qz-question-container {
  /*direction: rtl;*/
}
.qz-choices-container {
  padding-left: 100px;
  padding-right: 100px;
  /*margin-top: 30px;*/
}

.qz-correction {
  margin-top: 100px;
  margin-bottom: 140px;
}
.qz-correction .qz-title {
  font-size: 5rem;
  font-weight: bold;
  /*direction: rtl;*/
  margin-bottom: 30px;
}

.qz-correction .qz-body {
  padding-bottom: 20px;
  font-size: 3rem;
  line-height: 70px;
}

.qz-choice-button:disabled {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

.qz-header img {
  /*width: 100%;*/
  max-width: 100%;
}
