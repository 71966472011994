@font-face {
  font-family: "Axis";
  font-style: normal;
  font-weight: normal;
  src: local("Axis"), url("assets/fonts/axis.woff") format("woff");
}
@font-face {
  font-family: "Axis";
  src: url("assets/fonts/AXIS-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/AXIS-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Axis";
  src: url("assets/fonts/AXIS-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/AXIS-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@layer base {
  @font-face {
    font-family: "Showy";
    font-weight: bold;
    src: url("assets/fonts/SageHeadline-Black.ttf") format("opentype");
  }
}

html,
body,
p {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  background: url("./assets/img/splashscreen.webp");
  font-family: "Axis", sans-serif;
}

html,
body,
#root,
.App,
.wrapper-container,
.items-container,
.comparable-item {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
}

.comparator-container .items-container {
  flex-grow: 3;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.comparator-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
}

.wrapper-container {
  height: 100%;
}

.items-container .comparable-item {
  background-color: black;
  height: 100%;
  position: relative;
  background-size: cover;
}

.comparable-item .header {
  padding-top: 30px;
  font-weight: bold;
}

.comparable-item .title {
  font-size: 2rem;
  line-height: 0.5rem;
  text-align: right;
  color: black;
}

.comparable-item .subtitle {
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: right;
  color: black;
}

img.attribute-icon {
  max-width: 50px;
  margin-right: 10px;
}

.comparable-item .body {
  padding-top: 20px;
  padding-left: 30px;
  font-size: 14px;
  color: white;
  text-align: left;
}

.comparable-item .body ul {
  padding: 0;
  margin: 0;
  /*display: flex;
  flex-wrap: wrap;*/
}

.comparable-item .body li {
  list-style: none;
  /*width: 50%;*/
  margin-bottom: 14px;
}

.comparable-item .body p {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 0;
}

.comparable-item .body .colors {
  padding-bottom: 8px;
}

.comparable-item .body .colors span {
  display: inline-block;
  border-radius: 100%;
  border: 1px solid white;
  width: 15px;
  height: 15px;
}

.comparable-item .footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  color: white;
  background: rgb(31, 31, 29);
  background: linear-gradient(
    90deg,
    rgba(31, 31, 29, 1) 0%,
    rgba(39, 38, 36, 1) 100%
  );
  padding: 10px 0;
}
.comparable-item .image img {
  width: 100%;
  height: auto;
}
.comparable-item .video {
  margin-top: 20px;
}
.comparable-item .video video {
  width: 400px;
  max-width: 100%;
  height: auto;
}
.comparable-item .footer {
  text-align: center;
}
.comparable-item .footer p {
  text-transform: uppercase;
}

.items-1 .comparable-item .footer-container {
  background: transparent !important;
}

.comparable-item .footer p {
  margin: 0;
  font-size: 50px;
  line-height: 50px;
}

.items-1 .header {
  padding-top: 80px;
}

.items-1 .comparable-item .footer p {
  font-size: 150px;
  line-height: 150px;
}

.items-1 .title {
  line-height: 2.5rem;
  color: white;
}

.items-1 .subtitle {
  color: white;
  font-size: 3rem;
  line-height: 1rem;
  margin-bottom: 0;
}

.items-1 .content {
  display: flex;
}

.items-1 .content .image {
  width: 70%;
  padding: 40px 20px;
  max-height: 520px;
}

.items-1 .content .body {
  width: 30%;
}

.items-1 .footer {
  padding-bottom: 40px;
}

.items-1 .comparable-item img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.items-2 .header,
.items-3 .header {
  padding-top: 50px;
}

.items-2 .content .body,
.items-3 .content .body {
  margin-top: 40px;
  padding: 0 40px;
}

.items-2 .comparable-item .body span {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 14px;
}

.items-2 .comparable-item .body p {
  font-size: 44px;
  line-height: 44px;
  margin-bottom: 0;
}

.items-3 .comparable-item .body span {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 14px;
}

.items-3 .comparable-item .body p {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 0;
}

.scan-message {
  position: absolute;
  top: 20px;
  right: 20px;
}
#debug-output {
  position: fixed;
  width: 100%;
  height: 300px;
  background-color: grey;
}

.comparable-item .footer {
  text-align: center;
  font-weight: bold;
  padding-bottom: 15px;
}
.items-1 .footer p {
  font-size: 60px !important;
  line-height: 60px !important;
}
.comparable-item .title,
.comparable-item .subtitle {
  font-weight: bold !important;
}
.comparable-item .subtitle {
  padding-left: 60px;
}

.items-4 .body li {
  margin-bottom: 10px;
}

.body li {
  font-size: 22px;
}
.items-1 .body li {
  font-size: 30px;
}
.items-1 .title {
  font-size: 3rem !important;
  line-height: 3.5rem !important;
}
.items-1 .subtitle {
  font-size: 4rem !important;
  line-height: 2rem !important;
}
.items-1 .comparable-item .body span,
.items-2 .comparable-item .body span {
  font-size: 20px;
}
.items-1 .comparable-item .body p,
.items-2 .comparable-item .body p {
  font-size: 40px;
  font-weight: bold !important;
  margin-top: 6px;
}
.comparable-item .body {
  display: inline-block;
}
.comparable-item .body span {
  font-size: 24px !important;
}
.comparable-item .body p {
  font-size: 30px !important;
  font-weight: bold !important;
  margin-top: 2px !important;
}
.items-1 .body {
  margin-top: 80px;
  padding-left: 160px;
}
.items-1 .body li {
  margin-bottom: 24px;
}
.colors span {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid white !important;
}
.items-1 .comparable-item img {
  width: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

/*.comparable-item .body ul li:nth-child(odd) {color:red;margin:0;padding:0;}*/
/*.comparable-item .body ul li:nth-child(even) {margin-top:0;padding-top:0;}*/
.comparable-item .body ul li span {
  color: red;
}
.comparable-item .body ul li p {
  color: white;
}
.comparable-item .footer {
  text-align: center;
  font-weight: bold;
  background-color: #ad0c32;
  padding: 20px;
}
body .items-1 p.currency,
p.currency {
  font-size: 34px !important;
  line-height: 34px !important;
}
.items-1 .footer p.price {
  font-size: 60px !important;
  line-height: 60px !important;
}
.footer span {
  width: 100%;
  display: block;
  text-align: left;
}
.footer .currency {
  text-align: right;
}
.footer-container {
  background: transparent !important;
}
.comparable-item:nth-child(even) {
  background-color: #252424;
}
.comparable-item .title {
  color: white;
}
.comparable-item .subtitle {
  color: #ad0c32;
}
.footer-container {
  padding-bottom: 0 !important;
}
.items-2 .content .body ul,
.items-3 .content .body ul {
  float: none !important;
  margin: 0 auto !important;
}
body .ashnooes {
  background: transparent !important;
  opacity: 0;
}
body .items-1 .comparable-item img.attribute-icon {
  max-width: 50px !important;
  max-height: 50px !important;
}

.close-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  padding: 16px 20px 12px;
  background: black;
  border: red solid 1px;
  color: white;
  font-size: 44px;
  border-radius: 20px;
  margin: 20px;
  line-height: 36px;
}
.video-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  background-color: black;
}
.video-fullscreen video {
  width: 100%;
  height: 100%;
}

.stickyQrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #faf3dd;
}
.stickyQrCode .qrcode-container {
  background-color: #9a172e;
  border-radius: 10px;
  padding-top: 10px;
}

.stickyQrCode .qrcode-container {
  width: 80%;
}

.stickyQrCode .qrcode-container #qrcode-url-canvas {
  width: 100% !important;
}

.qr-code-label {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /*margin-top: 1rem;*/
}

.qr-code-top-icon {
  max-height: 250px;
  max-width: 100%;
  margin-bottom: 1rem;
}

.qrcode-url-canvas-container {
  background: white;
  border-radius: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.font-showy {
  font-family: "showy" !important;
  text-transform: capitalize;
  line-height: 100px;
  font-size: 110px;
  margin: -3px;
  margin-right: 15px;
}

.showy-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.showy-sup-text {
  top: 28px;
  font-size: 50px;
  padding-left: 5px;
}

.all-in-display {
  font-size: 16px;
  position: absolute;
  text-transform: none;
  top: 55px;
  right: 72px;
  font-weight: 400;
  font-family: sans-serif;
  letter-spacing: 2px;
}

.progress-bar-anim {
  background: #ed6a0f;
  height: 100%;
  position: absolute;
  transition: width 0.3s linear;
}

.progress-bar-anim::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, #ed6a0f 25%, #e0e0e0 50%, #ed6a0f 75%);
  background-size: 200% 100%;
  animation: shimmer-animation 2s infinite linear;
}

@keyframes shimmer-animation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
