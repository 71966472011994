.qz-finish {
  position: absolute;
  z-index: 99999;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.qz-finish-icon {
  margin-bottom: 40px;
}

.qz-finish .qz-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  padding: 0 20px;
}

.qz-title {
  font-size: 70px;
  font-weight: bold;
}

.qz-body {
  font-size: 50px;
}
