.tuto-header {
  margin-top: 90px;
}

.tuto-header img {
  display: inline-block;
}

.tuto {
  margin-top: 50px;
}

.tuto p {
  font-size: 34px;
}

.tuto img {
  margin-top: 30px;
}

.close-tutorial-btn {
  font-size: 28px;
  background: #e10a34;
  border: none;
  color: white;
  padding: 15px 20px;
  margin-top: 60px;
  border-radius: 20px;
}
