@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../../assets/fonts/NotoKufiArabic.woff2') format('woff2'),
  url('../../assets/fonts/NotoKufiArabic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('../../assets/fonts/NotoKufiArabic-Bold.woff2') format('woff2'),
  url('../../assets/fonts/NotoKufiArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.quiz_state {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

html, body, #root, .App, .wrapper-container {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
}

.App {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.Quiz.intro_state {
  background-image: none !important;
}
