@font-face {
  font-family: "Noto Kufi Arabic";
  src: url("../../assets/fonts/NotoKufiArabic.woff2") format("woff2"),
    url("../../assets/fonts/NotoKufiArabic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic";
  src: url("../../assets/fonts/NotoKufiArabic-Bold.woff2") format("woff2"),
    url("../../assets/fonts/NotoKufiArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.content-wall-component.state-url {
  flex-grow: 1;
}
/*.content-wall-component.state-url {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}*/
.content-wall-component.state-shake-it-get-it {
  height: 100%;
}
.content-wall-component.state-shake-it-get-it #container {
  height: 70%;
}
.content-wall-component.state-shake-it-get-it .side-bar {
  height: 30%;
  margin-top: 0;
  transform: none;
}
.content-wall-component.state-url .side-bar {
  margin-top: 50vh;
  transform: translateY(-50%);
}
.poster {
  height: 40%;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
}
#container {
  height: 100%;
  width: 100%;
  background-color: #000;
}
.content-wall-component.state-shake-it-get-it {
  height: 100%;
  width: 100%;
}
.main-carousel-container {
  width: 100%;
  height: 100%;
}
.side-bar {
  /*height: 30%;
  width: 100%;*/
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
}
.no-post {
  color: white;
  text-align: center;
}
.no-post img {
  margin-bottom: 10px;
}
.no-post p {
  font-size: 30px;
  text-transform: uppercase;
}
.step-2 {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}
.step-2-icon {
  width: 30px;
}
.step-2-icon-txt {
  display: inline-block;
}
#current-url {
  display: inline-block;
  margin: 0 auto;
  width: auto;
  border: solid transparent 10px;
  border-radius: 10px;
}
#current-url .scan-me {
  font-size: 30px;
  background-color: white;
  color: black;
}
#current-url .step-2-icon-txt span {
  color: white;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 50px;
  text-align: center;
  line-height: 18px;
}
#current-url .step-2-url {
  background-color: black;
  border-radius: 30px;
  padding: 20px 50px;
  color: #fbc900;
  margin: 20px 30px 0;
}
.icon {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 25px;
}
.message {
  border-radius: 30px;
  display: inline-block;
  margin: 20px auto 0;
  padding: 15px 32px;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  background-color: #86061f;
}
.phone-icon {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  margin-bottom: 25px;
  -webkit-animation-name: shakyPhone;
  animation-name: shakyPhone;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.carousel-control-next {
  /*display: none !important;*/
  background: url("../../assets/img/right-arrow.webp") no-repeat;
  width: 118px;
  height: 215px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel-control-prev span,
.carousel-control-next span {
  display: none;
}
.carousel-control-prev {
  /*display: none !important;*/
  background: url("../../assets/img/left-arrow.webp") no-repeat;
  width: 118px;
  height: 215px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.visually-hidden {
  display: none;
}

html,
body,
#root,
.App,
.carousel,
.carousel-inner,
.carousel-item {
  height: 100%;
  width: 100%;
}

.carousel {
  width: 100%;
  height: 800px;
  margin: auto;
}

.carousel-item video {
  width: 100%;
  height: 100%;
}
.carousel-item .title {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 40px;
  padding: 20px 0;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes shakyPhone {
  0% {
    -webkit-transform: rotate(0deg) skewY(0deg) translateX(0px);
    transform: rotate(0deg) skewY(0deg) translateX(0px);
  }
  2% {
    -webkit-transform: rotate(-3deg) skewY(0deg) translateX(-6px);
    transform: rotate(-3deg) skewY(0deg) translateX(-6px);
  }
  6% {
    -webkit-transform: rotate(4.5deg) skewY(0deg) translateX(10px);
    transform: rotate(4.5deg) skewY(0deg) translateX(10px);
  }
  10% {
    -webkit-transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
  }
  14% {
    -webkit-transform: rotate(4.5deg) skewY(0deg) translateX(10px);
    transform: rotate(4.5deg) skewY(0deg) translateX(10px);
  }
  18% {
    -webkit-transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
  }
  22% {
    -webkit-transform: rotate(3deg) skewY(0deg) translateX(6px);
    transform: rotate(3deg) skewY(0deg) translateX(6px);
  }
  25% {
    -webkit-transform: rotate(-1deg) skewY(0deg) translateX(-2px);
    transform: rotate(-1deg) skewY(0deg) translateX(-2px);
  }
  27% {
    -webkit-transform: rotate(0deg) skewY(0deg) translateX(0px);
    transform: rotate(0deg) skewY(0deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) skewY(0deg) translateX(0px);
    transform: rotate(0deg) skewY(0deg) translateX(0px);
  }
}
@keyframes shakyPhone {
  0% {
    -webkit-transform: rotate(0deg) skewY(0deg) translateX(0px);
    transform: rotate(0deg) skewY(0deg) translateX(0px);
  }
  2% {
    -webkit-transform: rotate(-3deg) skewY(0deg) translateX(-6px);
    transform: rotate(-3deg) skewY(0deg) translateX(-6px);
  }
  6% {
    -webkit-transform: rotate(4.5deg) skewY(0deg) translateX(10px);
    transform: rotate(4.5deg) skewY(0deg) translateX(10px);
  }
  10% {
    -webkit-transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
  }
  14% {
    -webkit-transform: rotate(4.5deg) skewY(0deg) translateX(10px);
    transform: rotate(4.5deg) skewY(0deg) translateX(10px);
  }
  18% {
    -webkit-transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(0deg) translateX(-10px);
  }
  22% {
    -webkit-transform: rotate(3deg) skewY(0deg) translateX(6px);
    transform: rotate(3deg) skewY(0deg) translateX(6px);
  }
  25% {
    -webkit-transform: rotate(-1deg) skewY(0deg) translateX(-2px);
    transform: rotate(-1deg) skewY(0deg) translateX(-2px);
  }
  27% {
    -webkit-transform: rotate(0deg) skewY(0deg) translateX(0px);
    transform: rotate(0deg) skewY(0deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) skewY(0deg) translateX(0px);
    transform: rotate(0deg) skewY(0deg) translateX(0px);
  }
}
@-webkit-keyframes shakyIllu {
  0% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  1% {
    -webkit-transform: rotate(-3deg) translateX(-12px);
    transform: rotate(-3deg) translateX(-12px);
  }
  3% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  5% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  7% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  9% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  11% {
    -webkit-transform: rotate(3deg) translateX(12px);
    transform: rotate(3deg) translateX(12px);
  }
  13% {
    -webkit-transform: rotate(-1deg) translateX(-4px);
    transform: rotate(-1deg) translateX(-4px);
  }
  15% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
}
@keyframes shakyIllu {
  0% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  1% {
    -webkit-transform: rotate(-3deg) translateX(-12px);
    transform: rotate(-3deg) translateX(-12px);
  }
  3% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  5% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  7% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  9% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  11% {
    -webkit-transform: rotate(3deg) translateX(12px);
    transform: rotate(3deg) translateX(12px);
  }
  13% {
    -webkit-transform: rotate(-1deg) translateX(-4px);
    transform: rotate(-1deg) translateX(-4px);
  }
  15% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
}
@-webkit-keyframes floatToPhone {
  0% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  20% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  40% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  65% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  70% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 0;
  }
  80% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  85% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  100% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes floatToPhone {
  0% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  20% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  40% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  65% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  70% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 0;
  }
  80% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  85% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  100% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes shakyElement {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  2% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  6% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  10% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  14% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  18% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  22% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  25% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  27% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes shakyElement {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  2% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  6% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  10% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  14% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  18% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  22% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  25% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  27% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#shaking-phone {
  width: 120px;
  margin: 0 auto 8px;
  height: 90px;
}
.carousel-item img {
  max-width: 100%;
  max-height: 100%;
}
.side-bar p {
  margin-bottom: 0;
}
#mainInteraction {
  text-align: center;
  height: 90px;
}
.shtxt {
  display: block;
  text-align: center;
  clear: both;
  background-color: black;
  border-radius: 30px;
  padding: 20px 50px;
  color: #fbc900;
  margin: 0px 20px 0;
}
.bar {
  width: 120px;
  height: 90px;
  max-height: 120px;
  margin: 0 auto;
}
.bar.red {
  background-color: #ed480e;
}
.bar.white {
  background-color: #fff;
}
.bar.contain {
  width: auto;
  display: inline-block;
}
.bar.baseline {
  position: absolute;
  top: 22px;
  margin-left: -166px;
}
#fallbackVisual {
  bottom: 0px;
}
#shakeVisual,
#fallbackVisual {
  visibility: visible;
  width: 100%;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
#shakeVisual .bar,
#fallbackVisual .bar {
  overflow: hidden;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
#shakeVisual .bar span.visualText,
#shakeVisual .bar a.visualText,
#fallbackVisual .bar span.visualText,
#fallbackVisual .bar a.visualText {
  position: relative;
  color: #fff;
  font-size: 62px;
  font-weight: 800;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  line-height: 85px;
  margin: 0;
  text-align: left;
  opacity: 1;
}
#shakeVisual .bar span.visualText.shaky,
#shakeVisual .bar a.visualText.shaky,
#fallbackVisual .bar span.visualText.shaky,
#fallbackVisual .bar a.visualText.shaky {
  -webkit-animation-name: shakyElement;
  animation-name: shakyElement;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
#shakeVisual .bar span.visualText.centerText,
#shakeVisual .bar a.visualText.centerText,
#fallbackVisual .bar span.visualText.centerText,
#fallbackVisual .bar a.visualText.centerText {
  text-align: center;
  padding: 0 20px;
  z-index: 29;
}
#shakeVisual .bar span.visualText.barOverlay,
#shakeVisual .bar a.visualText.barOverlay,
#fallbackVisual .bar span.visualText.barOverlay,
#fallbackVisual .bar a.visualText.barOverlay {
  z-index: 15;
}
#shakeVisual .bar span.visualText #textGetIt,
#shakeVisual .bar a.visualText #textGetIt,
#fallbackVisual .bar span.visualText #textGetIt,
#fallbackVisual .bar a.visualText #textGetIt {
  margin-right: 12px;
}
#shakeVisual .bar span.visualText #textIt,
#shakeVisual .bar a.visualText #textIt,
#fallbackVisual .bar span.visualText #textIt,
#fallbackVisual .bar a.visualText #textIt {
  margin-left: 20px;
  margin-right: 20px;
}
#shakeVisual .bar #phoneIcon,
#fallbackVisual .bar #phoneIcon {
  width: 52%;
  vertical-align: middle;
  opacity: 1;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
#shakeVisual .bar #phoneIcon.shaky,
#fallbackVisual .bar #phoneIcon.shaky {
  -webkit-animation-name: shakyPhone;
  animation-name: shakyPhone;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
#shakeVisual.hidden .bar,
#fallbackVisual.hidden .bar {
  max-height: 0px;
}
#shakeVisual.hidden .bar .visualText,
#fallbackVisual.hidden .bar .visualText {
  opacity: 0;
}
#shakeVisual.hidden .bar #phoneIcon,
#fallbackVisual.hidden .bar #phoneIcon {
  top: 0px;
  opacity: 0;
}
#qrcode-url-canvas {
  width: auto !important;
  height: auto !important;
}
