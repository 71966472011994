#valo-btn {
  position: fixed;
  right: 20px;
  bottom: -40px;
  background-color: transparent;
  border: none;
}

#valo {
  position: fixed;
  right: 30px;
  bottom: 0;
  width: 370px;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  box-shadow: 1px -4px 22px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px -4px 22px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px -4px 22px 0px rgba(0, 0, 0, 0.75);
}

#valo .welcome {
  color: #303030;
  font-size: 12px;
  text-align: left;
}

#valo .online {
  color: #9ba6b4;
  font-size: 14px;
  text-align: left;
}

#valo .valuate {
  color: #028142;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#valo .qrcode-wrapper {
  width: 220px;
  margin: 40px auto 0;
}

#valo .close {
  color: black;
}
.hidden {
  display: none;
}
