.start-btn {
  position: fixed;
  bottom: -80px;
  left: 20px;
  padding: 10px 100px;
  color: black;
  border: none;
  font-size: 45px;
  background: none;
}
