body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body .ashnoomulti-action {
  display: none;
}
body.show-ashnoo-widget .ashnoomulti-action {
  display: block;
}

/* MAIN INTRODCUTION */

.start-btn-img {
  opacity: 1;
}

#valo-btn {
  opacity: 0;
  transition: none !important;
}

.overlay {
  position: fixed;
  height: 100%;
  background: black;
  opacity: 0;
  /* transform: translateX(-110%); */
}

/* SCENE ONE */

.quiz_state,
.tuto-header > img,
.tuto > p,
.tuto > img,
.close-tutorial-btn {
  opacity: 0;
}

.tuto > p {
  transform: translateY(20px);
}
.tuto > img {
  transform: translateX(-20px);
}
.close-tutorial-btn {
  transform: translateY(-20px);
}
/* 
.close-screen {
  scale: 0;
} */

/* SCENE TWO */

.qz-question-text > p {
  transform: translateY(20px);
  opacity: 0;
}
.qz-choice-button > div > p {
  opacity: 0;
  transform: translateY(-20px);
}

.quiz-overlay {
  position: fixed;
  right: 0;
  background: black;
  height: 100%;
  z-index: 10;
}

/* CONGRATS SCEN */
.qz-body {
  opacity: 0;
  transform: translateY(-20px);
}

.qz-title {
  scale: 0;
}

/* COMPARATOR SCENE ONE */

.header > .title {
  opacity: 0;
  transform: translateY(-20px);
}
.header > .subtitle {
  opacity: 0;
  transform: translateY(20px);
}

.content > .image {
  opacity: 1;
  transform: translateX(0px);
}

.content > .body {
  opacity: 0;
  transform: translateX(60px);
}

.comparable-item > .footer-container > .col-auto {
  transform: translateY(100%);
  opacity: 0;
}

.footer > .price-label {
  transform: translateY(-10px);
  opacity: 0;
}

.footer > .price {
  transform: translateY(10px);
  opacity: 0;
}

.footer > .currency {
  transform: translateX(10px);
  opacity: 0;
}

.comparable-item {
  transform: translateX(-100%);
  opacity: 0;
}

.stickyQrCode {
  transform: translateX(100%);
  opacity: 0;
}

/* COMPARATOR SCENE TWO */

.multiple-item-0 {
  z-index: 10;
}
.multiple-item-1 {
  z-index: 5;
}

/* .multiple-item-1,
.multiple-item-2 {
  transform: translateX(-100%); */
/* z-index: -10; */
/* opacity: 0; */
/* } */
/* .multiple-item-2 {
  z-index: -20;
} */

.relative {
  position: relative;
}

.qrcode {
  position: relative;
  width: 40px;
  height: 40px;
  background: url("./assets/img/qr-code-gost.webp");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  margin: auto;
  top: 15%;
}

.qrcode::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("./assets/img/qr-code-active.webp");
  background-size: cover;
  animation: animCode 3s linear infinite;
}

@keyframes animCode {
  0%,
  100% {
    height: 0;
  }
  50% {
    height: 100%;
  }
}

.qrcode::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  width: 50px;
  margin-left: -24px;
  background: #33a6ff;
  filter: drop-shadow(0 0 0.75rem #33a6ff);
  animation: animLine 3s linear infinite;
}

@keyframes animLine {
  0%,
  100% {
    top: 0;
  }
  50% {
    top: 100%;
  }
}
.bg-black {
  background: black;
}
