.category-video-container {
  width: 100%;
}
.category-video-container video {
  width: 90%;
  height: 100%;
  margin: 60px 5% 70px;
  border: 5px solid black;
}
.next-category {
  background-color: black;
  padding: 10px 100px;
  color: white;
  border: none;
  font-size: 45px;
}

.header img {
  width: 100%;
  max-width: 100%;
}
